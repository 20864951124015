import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import { Box, Button, CircularProgress } from '@mui/material'
import { useSignInMutation } from 'api/authApi'
import { path, toLower, defaultTo, prop } from 'ramda'
import PropTypes from 'prop-types'
import { useAnalytics, useLocalStorage, useSession } from 'hooks'
import { useSelector } from 'react-redux'

import EmailAndPassword from 'components/authentication/EmailAndPasswordConnector'
import ErrorMessage from 'components/common/ErrorMessage'
import { useNavigate } from 'react-router-dom'

const BasicSignIn = ({
  email = null,
  password = null,
  resetState,
  setUserData
}) => {
  const { domain } = useSelector(prop('school'))
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()
  const { save } = useLocalStorage()

  const [signIn, { isError, isLoading, isSuccess, data, error }] =
    useSignInMutation()

  const onSubmit = () => {
    const body = {
      auth_type: 'traditional',
      email: toLower(email),
      org_domain: domain,
      password
    }
    signIn(body)
  }

  const handleAnalytics = token => {
    const promises = [
      sessionEvent('login', { session_id: token }),
      sendEvent('login', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: true
      }),
      sendEvent('sign-in', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: true
      })
    ]
    return Promise.allSettled(promises)
  }

  useEffect(() => {
    if (isSuccess) {
      const sessionId = path(['headers', 'headers'], data).get('X-Session-Id')
      save(sessionId)
      // Tracking
      GA.click('Login', 'Click')
      handleAnalytics(sessionId)
        .then(() => {
          setUserData(path(['res', 'user'], data))
          resetState()
        })
        .finally(() => navigate('/home'))
    }
  }, [isSuccess, data])

  return (
    <Box
      component="form"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <ErrorMessage
        hasError={isError}
        message={defaultTo(
          path(['data', 'email'], error),
          path(['data', 'errors', 'email'], error)
        )}
      />
      <EmailAndPassword />
      <Button disabled={isLoading} fullWidth type="submit">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        )}
      </Button>
    </Box>
  )
}

BasicSignIn.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  resetState: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired
}

export default BasicSignIn
